'use client';

import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from '@/i18n/client';
import Link from 'next/link';
import Image from 'next/image';

const LKFooter = ({ lang }) => {
  const { t } = useTranslation(lang);

  return (
    <footer>
      <div className="site">
        <Container fluid={'lg'}>
          <Row className="g-4">
            <Col md={4} xs={12}>
              <p className="fs-6 mb-4">Lumberjack 🪓</p>
              <p>Lumberjack {t('LKFooter/key9')}</p>
            </Col>
            <Col md={3} xs={4} className="ps-md-5">
              <p className="fs-6 mb-4">{t('LKFooter/key8')}</p>
              <ul className="mb-0 p-0 list-unstyled">
                <li className="mb-3">
                  <Link href={`/home/${lang}/plans`}>{t('LKFooter/key6')}</Link>
                </li>
                <li className="mb-3">
                  <Link href={`/home/${lang}/demo`}>{t('LKFooter/key3')}</Link>
                </li>
                <li className="mb-3">
                  <Link href={`/home/${lang}/news`}>{t('LKFooter/key5')}</Link>
                </li>
              </ul>
            </Col>
            <Col md={3} xs={4}>
              <p className="fs-6 mb-4">{t('LKFooter/key1')}</p>

              <ul className="mb-0 p-0 list-unstyled">
                <li className="mb-3">
                  <Link href={`/home/${lang}/contact-us`}>{t('LKFooter/key2')}</Link>
                </li>
                <li className="mb-3">
                  <Link href={`/home/${lang}/tos`}>{t('LKFooter/key7')}</Link>
                </li>
                {/* <li>
                  <a href="mailto:info@l-k.io">info@l-k.io</a>
                </li> */}
              </ul>
            </Col>
            <Col md={2} xs={4}>
              <Image
                src={'https://cdn.pinkswan.ch/application/LOUISEBOT/24041606581499_logo-gdpr-compliant.svg'}
                alt="GDRP"
                width={100}
                height={100}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-mute text-center">
        <small className="text-center mb-0">
          © {new Date().getFullYear()} - Lumberjack / l-k.io - {t('LKFooter/key4')} - version:{' '}
          {process.env.NEXT_PUBLIC_APP_VERSION}
        </small>
      </div>
    </footer>
  );
};

export default LKFooter;
